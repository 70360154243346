import React from 'react';
import alexaWebp from 'assets/images/alexa.webp';
import alexaPng from 'assets/images/alexa.png';
import heyGoogleWebp from 'assets/images/hey-google.webp';
import heyGooglePng from 'assets/images/hey-google.png';
import HomeAlexaGoogle from 'assets/images/Home_Alexa_Google.mp4';
import LazyLoad from 'react-lazyload';
import loadable from '@loadable/component';

const FadeIn = loadable(() => import('../common/FadeIn'));

const VoiceBlock = () => (
  <section className='voice-block'>
    <div className='voice-block__container'>
      <div className='voice-block__content'>
        <h2 className='voice-block__title'>Cleaner air is just an ask away.</h2>
        <h3 className='voice-block__subtitle'>
          Mila works with your favorite voice assistants to make knowing more about
          your air quality even easier.
        </h3>
        <div className='voice-block__logo'>
          <figure className='voice-block__logo-image'>
            <a
              href='https://alexa.amazon.com/spa/index.html#skills/dp/B098XF1CL8/?ref=skill_dsk_skb_sr_0&qid=cf2b5a18-008c-4a43-abe3-0b448504abab'
              target='_blank'
              rel='noreferrer'
            >
              <span className='screen-reader-text'>amazon alexa</span>
              <LazyLoad>
                <FadeIn>
                  <picture>
                    <source srcSet={alexaWebp} type='image/webp' />
                    <source srcSet={alexaPng} type='image/png' />
                    <img
                      alt='alexa'
                      src={alexaPng}
                    />
                  </picture>
                </FadeIn>
              </LazyLoad>
            </a>
          </figure>
          <figure className='voice-block__logo-image'>
            <a href='https://assistant.google.com/services/a/uid/000000a55fc9a9d1' target='_blank' rel='noreferrer'>
              <span className='screen-reader-text'>google assistant</span>
              <LazyLoad>
                <FadeIn>
                  <picture>
                    <source srcSet={heyGoogleWebp} type='image/webp' />
                    <source srcSet={heyGooglePng} type='image/png' />
                    <img
                      alt='hey google'
                      src={heyGooglePng}
                    />
                  </picture>
                </FadeIn>
              </LazyLoad>
            </a>
          </figure>
        </div>
      </div>
    </div>
    <video
      className='voice-block__video'
      playsInline
      webkit-playsinline='true'
      autoPlay
      muted
      loop
    >
      <source src={HomeAlexaGoogle} type='video/mp4' />
    </video>
  </section>
);

export default VoiceBlock;
